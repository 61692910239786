<template>
  <v-dialog v-model="isDialogVisible" persistent max-width="1200px">
    <v-card>
      <v-card-title>
        <span class="headline">Purchase Order(s)</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">PO No</th>
                      <th class="text-left">Supplier</th>
                      <th class="text-left">Delivery Date</th>
                      <th class="text-left">Delivery Place</th>
                      <th class="text-left">Payment Term</th>
                      <th class="text-left">Delivery Term</th>
                      <th class="text-left">Purpose/Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(po, key) in pos" :key="key">
                      <td>
                        <router-link
                          class="font-weight-semibold text-decoration-none"
                          :to="{ name: 'purchase-orders-edit', params: { id: po.id } }"
                        >
                          {{ po.po_number }}
                        </router-link>
                      </td>
                      <td>{{ po.supplier.supplier_name }}</td>
                      <td>{{ po.delivery_date }}</td>
                      <td>{{ po.delivery_place }}</td>
                      <td>{{ po.payment_term }}</td>
                      <td>{{ po.delivery_term }}</td>
                      <td>{{ po.remarks }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="isDialogVisible = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  props: {
    pos: null,
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(false)

    return {
      isDialogVisible,
    }
  },
}
</script>
