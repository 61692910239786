<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Purchase Order</span>
      </v-card-title>

      <v-card-text>
        <v-card-subtitle>
          The system will auto create PO for suppliers/bidders with lowest unit price (based on Lowest Complying Bidder
          column).
        </v-card-subtitle>
        <v-container>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="form.payment_term"
                  :items="paymentTerms"
                  hide-details="auto"
                  label="Payment Term"
                  outlined
                  dense
                  hide-no-data
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="form.delivery_term"
                  :items="deliveryTerms"
                  hide-details="auto"
                  label="Delivery Term"
                  outlined
                  dense
                  hide-no-data
                >
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="form.procurement_mode"
                  :items="procurementModes"
                  hide-details="auto"
                  label="Mode of Procurement"
                  outlined
                  dense
                  hide-no-data
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="form.delivery_place"
                  outlined
                  dense
                  label="Delivery Place"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  v-model="poDatePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.po_date"
                      readonly
                      label="PO Date"
                      v-bind="attrs"
                      outlined
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.po_date"
                    color="primary"
                    @input="poDatePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  v-model="deliveryDatePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.delivery_date"
                      readonly
                      label="Delivery Date"
                      v-bind="attrs"
                      outlined
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.delivery_date"
                    color="primary"
                    @input="deliveryDatePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="saving"
          @click="create"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from '@axios'
import useUIResolver from '../useUIResolver'

const formInit = () => ({
  supplier_id: null,
  rfq_id: null,
  delivery_date: null,
  delivery_place: null,
  payment_term: 'Regular Payment',
  delivery_upon_approved: 1,
  procurement_mode: null,
  po_date: null,
})

export default {
  props: {
    abstract: null,
  },
  setup(props, { emit }) {
    const saving = ref(false)
    const isDialogVisible = ref(false)
    const deliveryDatePicker = ref(false)
    const poDatePicker = ref(false)

    const form = ref(formInit())
    const errors = ref([])
    const { deliveryTerms, paymentTerms, procurementModes } = useUIResolver()
    const create = () => {
      saving.value = true
      axios
        .post('/purchase-orders/create-from-abstract', { ...form.value, ...props.abstract })
        .then(r => {
          saving.value = false
          isDialogVisible.value = false
          emit('saved')
        })
        .catch(error => {
          if (error.response.status === 422) {
            errors.value = error.response.data.errors
          }
          saving.value = false
        })
    }

    return {
      saving,
      deliveryDatePicker,
      form,
      errors,
      deliveryTerms,
      poDatePicker,
      paymentTerms,
      procurementModes,

      isDialogVisible,

      create,
    }
  },
}
</script>
