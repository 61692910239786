<template>
  <v-card>
    <v-card-title>
      Abstract <v-chip
        v-if="form.lot_supplier"
        color="warning"
        small
        class="ml-2"
      >
        <v-icon
          small
          class="mr-1"
        >
          {{ icons.mdiStar }}
        </v-icon>
        AWARDED BY LOT
      </v-chip>
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="purchaseOrders.length === 0"
        color="primary"
        class="mr-2"
        @click="createPO"
      >
        <v-icon
          small
        >
        </v-icon>
        <span class="pl-2">Create Purchase Order</span>
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="mr-2"
        @click="viewPO"
      >
        <v-icon
          small
        >
        </v-icon>
        <span class="pl-2">Views Purchase Order(s)</span>
      </v-btn>
      <v-btn
        color="primary"
        class="mr-2"
        outlined
        @click="generatePdf(0)"
      >
        <v-icon
          small
        >
          {{ icons.mdiExportVariant }}
        </v-icon>
        <span class="pl-2">Export PDF</span>
      </v-btn>
      <v-btn
        color="primary"
        class="mr-2"
        outlined
        @click="generatePdf(1)"
      >
        <v-icon
          small
        >
          {{ icons.mdiExportVariant }}
        </v-icon>
        <span class="pl-2">Export PDF (EMPTY SUPPLIER)</span>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="rfq.quotation_number"
              label="Quotation No"
              outlined
              dense
              readonly
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="rfq.pr_number"
              label="PR No"
              outlined
              dense
              readonly
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="rfq.office_name"
              label="Office"
              outlined
              dense
              readonly
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="rfq.abc"
              label="ABC"
              outlined
              dense
              readonly
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              :value="rfq.canvasser ? rfq.canvasser.full_name : ''"
              label="Canvasser"
              outlined
              dense
              readonly
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              :value="rfq.supply_officer ? rfq.supply_officer.full_name: ''"
              label="Supply Officer"
              outlined
              dense
              readonly
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-tabs
      v-model="rfqTab"
      fixed-tabs
    >
      <v-tab>
        <h4>Items ({{ form.items.length }})</h4>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="rfqTab">
      <v-tab-item>
        <v-card>
          <v-card-actions v-if="purchaseOrders.length === 0 && !form.lot_supplier">
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              @click="awardDialogVisible = true"
            >
              <v-icon
                color="warning"
                small
                class="mr-2"
              >
                {{ icons.mdiStar }}
              </v-icon>
              <span>Award by LOT</span>
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Description
                      </th>
                      <th class="text-left">
                        Unit Cost
                      </th>
                      <th
                        v-for="(supplier, key) in suppliers"
                        :key="key"
                        class="text-left"
                      >
                        <span style="color:#9155FD">{{ supplier.supplier.supplier_name }}</span>
                        </v-chipsmall>
                      </th>
                      <th class="text-left">
                        Lowest Complying Bidder
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in form.items"
                      :key="item.name"
                      :class="item.same_price_suppliers && purchaseOrders.length === 0 ? 'match-bid-price' : ''"
                    >
                      <td style="width: 2%">
                        {{ key + 1 }}
                      </td>
                      <td style="width: 30%">
                        {{ item.item.description }}
                      </td>
                      <td style="width: 10%">
                        {{ item.unit_cost }}
                      </td>
                      <td
                        v-for="(supplier, supplierIndex) in suppliers"
                        :key="supplierIndex"
                        class="text-left"
                      >
                        {{ getSupplierUnitPrice(supplier, item) }}
                      </td>
                      <td style="width: 10%">
                        <template v-if="!form.lot_supplier">
                          <template v-if="!item.same_price_suppliers">
                            <v-icon
                              color="warning"
                            >
                              {{ icons.mdiStar }}
                            </v-icon>
                            {{ getLowestComplyingBidder(item) }}
                          </template>
                          <template v-else>
                            <v-select
                              v-model="item.lowest_bidder_id"
                              :items="item.same_price_suppliers"
                              label=""
                              :disabled="purchaseOrders.length > 0"
                              item-value="id"
                              item-text="supplier_name"
                              @change="changeLowestBidder(item)"
                            ></v-select>
                          </template>
                        </template>
                        <template v-else>
                          <v-icon
                            color="warning"
                          >
                            {{ icons.mdiStar }}
                          </v-icon>
                          {{ form.lot_supplier.supplier_name }}
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <editor-dialog
      ref="poEditorDialog"
      :abstract="form"
      @saved="fetchPurchaseOrders"
    ></editor-dialog>
    <purchase-orders-dialog
      ref="poDialog"
      :pos="purchaseOrders"
    ></purchase-orders-dialog>
    <v-dialog
      v-model="awardDialogVisible"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Select Supplier to Award</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  v-model="lotSupplierId"
                  :items="suppliers"
                  label="Supplier"
                  dense
                  item-value="supplier.id"
                  item-text="supplier.supplier_name"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="awardDialogVisible = false"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            :disabled="!lotSupplierId"
            @click="awardByLot"
          >
            Award
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import { useRouter } from '@core/utils'
import {
  mdiCalendarCheckOutline, mdiClose, mdiCogOutline, mdiExportVariant, mdiPencilOutline, mdiPlus, mdiSpeedometer, mdiStar, mdiStarShooting,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import router from '@/router'
import EditorDialog from '../purchase-orders/EditorDialog.vue'
import PurchaseOrdersDialog from '../purchase-orders/PurchaseOrdersDialog.vue'
import usePurchaseOrders from '../purchase-orders/usePurchaseOrders'
import useRfq from './useRfq'
import useRfqItems from './useRfqItems'
import useRfqSuppliers from './useRfqSuppliers'

const formInit = () => ({
  rfq_id: null,
  quotation_number: null,
  supplier_id: null,
  lot_supplier: null,
  items: [],
  id: null,
})
export default {
  components: { EditorDialog, PurchaseOrdersDialog },
  setup() {
    const lotSupplierId = ref(null)
    const awardDialogVisible = ref(false)
    const suppliers = ref([])
    const rfqTab = ref(null)
    const form = ref(formInit())
    const { route } = useRouter()
    const { rfqSupplier, getRfqSupplier } = useRfqSuppliers()
    const { rfqItems, getRfqItems } = useRfqItems()
    const { rfq, getRfq } = useRfq()
    const poEditorDialog = ref(null)
    const poDialog = ref(null)

    const { purchaseOrders, getPurchaseOrders, loading: loadingPurchaseOrder } = usePurchaseOrders()

    const generatePdf = empty => {
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-abstract/${rfq.value.id}?empty_suppliers=${empty}`
    }

    const createPO = () => {
      poEditorDialog.value.isDialogVisible = true
    }

    const viewPO = () => {
      poDialog.value.isDialogVisible = true
    }

    const getSupplierUnitPrice = (rfqSupplierRow, items) => {
      if (items.supplier_items.length === 0) {
        return null
      }
      const row = items.supplier_items.find(item => item.rfq_supplier_id === rfqSupplierRow.id)

      return row?.unit_price
    }

    const getLowestComplyingBidder = item => {
      if (!item.lowest_bidder) {
        return null
      }

      return item.lowest_bidder?.rfq_supplier.supplier.supplier_name
    }

    const changeLowestBidder = item => {
      axios.put(`/rfq-items/change-bidder/${item.id}`, { supplier_id: item.lowest_bidder_id })
        .then(() => {

        })
    }

    const fetchPurchaseOrders = () => {
      getPurchaseOrders({ rfq_id: router.currentRoute.params.id })
    }

    const awardByLot = () => {
      axios.put(`/rfqs/award-by-lot/${form.value.rfq_id}`, { supplier_id: lotSupplierId.value })
        .then(r => {
          awardDialogVisible.value = false
          form.value.lot_supplier = r.data.lot_supplier
          fetchPurchaseOrders()
        })
    }

    onMounted(() => {
      if (router.currentRoute.params.id) {
        // get rfq
        getRfq(router.currentRoute.params.id).then(r => {
          form.value.rfq_id = r.data.id
          form.value.quotation_number = r.data.quotation_number
          form.value.lot_supplier = r.data.lot_supplier
          suppliers.value = r.data.suppliers
        })

        // get rfq_items
        getRfqItems({ rfq_id: router.currentRoute.params.id }).then(r => {
          form.value.items = r.data
        })

        fetchPurchaseOrders()
      }
    })

    return {
      awardDialogVisible,
      lotSupplierId,
      fetchPurchaseOrders,
      poEditorDialog,
      poDialog,

      generatePdf,
      createPO,
      viewPO,
      getSupplierUnitPrice,
      getLowestComplyingBidder,
      changeLowestBidder,
      awardByLot,

      rfqTab,
      suppliers,
      form,
      rfqSupplier,
      getRfqSupplier,

      rfqItems,
      getRfqItems,

      rfq,
      getRfq,

      purchaseOrders,
      getPurchaseOrders,
      loadingPurchaseOrder,

      // icons
      icons: {
        mdiPlus,
        mdiCalendarCheckOutline,
        mdiExportVariant,
        mdiSpeedometer,
        mdiStarShooting,
        mdiCogOutline,
        mdiStar,
        mdiClose,
        mdiPencilOutline,
      },
    }
  },
}
</script>
